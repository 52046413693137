import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./TheBlindCvToolPartner.scss"

export default function TheBlindCvToolPartner(props) {
  const Partners = data => {
    let partnersList = data.data.map((value, i) => {
      let PartnerImage = getImage(
        value.partner.localFile.childImageSharp.gatsbyImageData
      )
      return (
        <div className="col-12 col-md-6 partners-list" key={i}>
          <a
            href={value.partner_link}
            target="_blank"
            rel="noreferrer"
            data-analytics={`{"event":"cta_interaction",
              "click_text":"${value.partner.alt}",
              "destination_url":"${value.partner_link}",
              "click_type":"External",
              "module":"Partners"
            }`}
          >
            <GatsbyImage
              image={PartnerImage}
              alt={value.partner.alt}
              className="partner-logo"
            />
          </a>
        </div>
      )
    })
    return partnersList
  }

  return (
    <section className="blindcvtoolpartner">
      <h4>{props.data.partner_label}</h4>
      <div className="row">
        <Partners data={props.data.partners}></Partners>
      </div>
    </section>
  )
}
