import React from "react"
import NavigationBar from "../components/global/NavigationBar/NavigationBar"
import TheBlindCvToolIntro from "../components/TheBlindCvToolIntro/TheBlindCvToolIntro"
import TheBlindCvTool from "../components/TheBlindCvTool/TheBlindCvTool"
import TheBlindCvToolPartner from "../components/TheBlindCvToolPartner/TheBlindCvToolPartner"
import SiteFooter from "../components/global/SiteFooter/SiteFooter"
import Layout from "../components/Layout/Layout"
import { graphql, useStaticQuery } from "gatsby"

const BlindCvTool = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      prismicBlindcvCvToolsPage {
        data {
          analytics {
            document {
              ... on PrismicBlindcvPageAnalytics {
                data {
                  event
                  page_title
                  page_type
                }
              }
            }
          }
          cv_tool {
            document {
              ... on PrismicBlindcvRichHeading {
                id
                data {
                  heading {
                    text
                    html
                  }
                  description {
                    text
                    html
                  }
                  primary_image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  secondary_image {
                    alt
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
          cv_tool_container {
            document {
              ... on PrismicBlindcvCvToolContainer {
                id
                data {
                  title
                  pdf_image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  transcript
                  pdf_label
                  pdf_file {
                    url
                  }
                  top_summary {
                    text
                    html
                  }
                  description {
                    text
                    html
                  }
                  yes_image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  download_image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  download_label
                  download_link
                  read_label {
                    text
                    html
                  }
                  read_link
                  read_title {
                    text
                    html
                  }
                  right_image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  partner_label
                  partners {
                    partner {
                      alt
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    partner_link
                  }
                  background_color
                }
              }
            }
          }
        }
      }
    }
  `)

  const cvTool = data.prismicBlindcvCvToolsPage.data.cv_tool.document.data
  const cvToolContainer =
    data.prismicBlindcvCvToolsPage.data.cv_tool_container.document.data

  return (
    <div>
      <Layout
        pageViewAnalyticsData={
          data.prismicBlindcvCvToolsPage.data.analytics.document.data
        }
      >
        <NavigationBar />
        <div className="page-content blindcvtool">
          <TheBlindCvToolIntro data={cvTool} />
          <TheBlindCvTool data={cvToolContainer} />
          <TheBlindCvToolPartner data={cvToolContainer} />
          <SiteFooter />
        </div>
      </Layout>
    </div>
  )
}
export default BlindCvTool
