import React from "react"
import { Slide, Fade } from "react-reveal"
import { Markup } from "interweave"
import JSONAnimationHover from "../JSONAnimationHover/JSONAnimationHover"
import Download from "./../../images/Animations/Download.json"
import "./TheBlindCvToolIntro.scss"

export default function TheBlindCvToolIntro(props) {
  return (
    <Slide top>
      <section className="investigationIntro blindcvtool">
        <div className="container">
          <div className="row">
            <div className=" col-12 col-md-8 col-lg-8">
              <Fade top delay={1000} duration={1000} distance={"100px"}>
                <h1>
                  <Markup content={props.data.heading.text}></Markup>
                </h1>
                <p className="content-maxlength">
                  <Markup content={props.data.description.html}></Markup>
                </p>
              </Fade>
            </div>
            <div className="col-md-4 col-lg-4 imageCenter">
              <p>THE ANON CV TOOL</p>
              <JSONAnimationHover
                normal={Download}
                className={`arrowImg item-img download-arrow`}
                hasHover={true}
                loop={1}
              />
              <img
                className="investimg"
                src={props.data.secondary_image.localFile.publicURL}
                alt={props.data.secondary_image.alt}
              />
            </div>
          </div>
        </div>
      </section>
    </Slide>
  )
}
