import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Markup } from "interweave"
import "./TheBlindCvTool.scss"
import Subtractright from "./../../images/Subtractright.png"
import Subtractleft from "./../../images/Subtractleft.png"
import Subtractblueright from "./../../images/Subtractrightblue.png"
import Subtractblueleft from "./../../images/Subtractleftblue.png"
import JSONAnimationHover from "../JSONAnimationHover/JSONAnimationHover"
import LighteningBolt from "./../../images/Animations/Lightening_bolt.json"
import Download from "./../../images/Animations/Download.json"
import { Link } from "gatsby"

export default function TheBlindCvTool(props) {
  const pdfImage = getImage(
    props.data.pdf_image.localFile.childImageSharp.gatsbyImageData
  )
  const iconYes = getImage(
    props.data.yes_image.localFile.childImageSharp.gatsbyImageData
  )
  return (
    <section className="investigation-Tool blindcvtool">
      <div className="container container-sm">
        <div className="row">
          <h2 className="col-8 col-md-7 col-lg-5">{props.data.title}</h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-8 ">
            <div className="top description">
              <Markup content={props.data.top_summary.text}></Markup>
            </div>
          </div>
        </div>

        <div className="row summary-content">
          <div className="col-12 col-md-12 col-lg-8 card-content">
            <img
              src={Subtractright}
              alt="Right arrow"
              className="eclipsecut right"
            ></img>
            <img
              src={Subtractleft}
              alt="Left arrow"
              className="eclipsecut left"
            ></img>

            <div className="row card-1">
              <div className="card-body">
                <a
                  href={props.data.pdf_file.url}
                  target="_blank"
                  rel="noreferrer"
                  data-analytics={`{"event":"cta_interaction",
                    "click_text":"${props.data.pdf_label.replace(
                      /(?:\r\n|\r|\n)/g,
                      " "
                    )}",
                    "destination_url":"${props.data.pdf_file.url}",
                    "click_type":"External",
                    "module":"Anon CV Tool"
                  }`}
                >
                  <figure>
                    <GatsbyImage
                      className="main-img"
                      image={pdfImage}
                      alt={props.data.pdf_image.alt}
                    />
                    <figcaption>PDF({props.data.transcript})</figcaption>
                  </figure>
                </a>
                <a
                  href={props.data.pdf_file.url}
                  target="_blank"
                  rel="noreferrer"
                  data-analytics={`{"event":"cta_interaction",
                    "click_text":"${props.data.pdf_label.replace(
                      /(?:\r\n|\r|\n)/g,
                      " "
                    )}",
                    "destination_url":"${props.data.pdf_file.url}",
                    "click_type":"External",
                    "module":"Anon CV Tool"
                  }`}
                >
                  <span className="button turquoise-blue">
                    {props.data.pdf_label}
                  </span>
                </a>
                <div className="description">
                  <Markup content={props.data.description.text}></Markup>
                </div>
                <div className="relative">
                  <GatsbyImage
                    className="yesImg"
                    image={iconYes}
                    alt={props.data.yes_image.alt}
                  />

                  <div className="downloadImg">
                    <a
                      href={props.data.download_link}
                      target="_blank"
                      className="download-cta"
                      rel="noreferrer"
                      data-analytics={`{"event":"cta_interaction",
                        "click_text":"GET the anon cv tool",
                        "destination_url":"${props.data.download_link}",
                        "click_type":"External",
                        "module":"Anon CV Tool"
                      }`}
                    >
                      <JSONAnimationHover
                        normal={Download}
                        className={`arrowImg item-img download-arrow`}
                        hasHover={true}
                        loop={1}
                      />
                      <span className="button link-green">
                        <Markup content={props.data.download_label}></Markup>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 card-2">
            <Link
              to={props.data.read_link}
              className="card-hover"
              data-analytics={`{"event":"cta_interaction",
              "click_text":"Read the investigation",
              "destination_url":"${props.data.read_link}",
              "click_type":"Internal",
              "module":"Anon CV Tool"
            }`}
            >
              <div className="row">
                <div className="col-6 col-lg-12 col-md-6 h3-set">
                  <img
                    src={Subtractblueright}
                    alt="Right arrow"
                    className="eclipsecut left"
                  ></img>
                  <img
                    src={Subtractblueleft}
                    alt="Left arrow"
                    className="eclipsecut right"
                  ></img>
                  <h3>
                    <Markup content={props.data.read_title.text}></Markup>
                  </h3>
                </div>
                <div className="col-6 col-lg-12 col-md-6">
                  <div className="image-block">
                    <JSONAnimationHover
                      normal={LighteningBolt}
                      className={`arrowImg item-img down-arrow`}
                      hasHover={true}
                      loop={1}
                    />
                    <Markup content={props.data.read_label.text}></Markup>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
